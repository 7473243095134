// @ts-nocheck
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./partials/Navigation";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "./providers/ThemeProvider";
import { AppStoreProvider } from "./AppStore";

export default function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <AppStoreProvider>
          <SnackbarProvider maxSnack={3}>
            <Navigation />
          </SnackbarProvider>
        </AppStoreProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
