//@ts-nocheck
import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { Box } from "@material-ui/core";
import RouteLeftMenu from "./RouteLeftMenu";
import { useAppStore } from "../AppStore";

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
  })
);

export default function LeftMenu() {
  const classes = useStyles();
  const [VALUE, HANDLER] = useAppStore();

  const handleDrawer = () => {
    HANDLER.setIsMenuOpen((prev) => !prev);
  };

  return (
    <Box display="flex" flexGrow={1}>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !VALUE.isMenuOpen && classes.drawerPaperClose
          ),
        }}
        open={VALUE.isMenuOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawer}>
            {VALUE.isMenuOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <RouteLeftMenu
            openMenu={VALUE.isMenuOpen}
            currentUserRole={VALUE.currentUser.role}
          />
        </List>
      </Drawer>
    </Box>
  );
}
