// @ts-nocheck
import React, { useState } from "react";
import { TextField, Grid, Button, IconButton } from "@material-ui/core";
import BasicDialogue from "./BasicDialogue";
import { onlyNumbersValidation } from "../../utils/validations";
import EditIcon from "@material-ui/icons/Edit";

export default function EditChannels({ cameras, id, handler }) {
  const [openModal, setOpenModal] = useState(false);
  const [camerasCount, setCamerasCount] = useState(cameras || "");

  const handleClearForm = () => {
    setCamerasCount(cameras);
    setOpenModal(!openModal);
  };

  const isOldState = camerasCount === cameras;
  const channelsLength = Number(camerasCount) > 100;

  const Content = (
    <Grid container spacing={2} direction="column">
      <Grid item xs>
        <TextField
          name="cameras_count"
          size="small"
          fullWidth
          label="Channels"
          variant="outlined"
          value={camerasCount}
          onChange={(e) => setCamerasCount(e.target.value)}
          error={
            !onlyNumbersValidation(camerasCount) ||
            camerasCount === "" ||
            channelsLength
          }
          helperText={
            (!onlyNumbersValidation(camerasCount) ||
              camerasCount === "" ||
              channelsLength) &&
            "The Channels count field accepts only numbers from 1 to 100"
          }
          placeholder="Please indicate the number of channels"
        />
      </Grid>
    </Grid>
  );

  const Action = (
    <>
      <Button variant="outlined" color="primary" onClick={handleClearForm}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handler(id, camerasCount)}
        disabled={
          !onlyNumbersValidation(camerasCount) || isOldState || channelsLength
        }
      >
        Submit
      </Button>
    </>
  );

  return (
    <>
      <BasicDialogue
        show={openModal}
        handleClose={handleClearForm}
        title="Edit Number of Channels"
        Content={Content}
        Action={Action}
      />
      <IconButton color="primary" onClick={() => setOpenModal(!openModal)}>
        <EditIcon />
      </IconButton>
    </>
  );
}
