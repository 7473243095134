// @ts-nocheck

// import { THEME_BRAND } from "./utils/constans";

export const colors = {
  primary: "#0B92B7",
  secondary: "#38A699",
  dark: "#0D0E0F",
  dark2: "#171C1F",
  dark3: "#121517",
  dark4: "#333333",
  dark5: "#424242",
  dark6: "#506670",
  dark7: "#212A2F",
  dark8: "#181E20",
  accent: "#4191B3",
  blueGray: "#698795",

  white: "#fff",
  green: "#6FCF97",
  orange: "#FFA360",
  blue: "#6CADDF",
  red: "#F27077",
  purple: "#AE77B2",
  pink: "#E194BC",

  textGray: "#717070",
  menuActive: "#171C1F",
  borders: "#1D2529",
};

export const vars = {
  // background: "background.png",
  primaryBorderRadius: 20,
  primarySpacing: 16,

  primary: "#BB0000",
  secondary: "#660000",
  primaryBackground: "#2c618f",

  inputBackground: "#00FFFF",
  inputBorderRadius: 5,
  inputBorderColor: "#FF00FF",

  primaryBorderColor: "#00FF00",

  defaultBackground: "#ffb700",

  global: {},
};

export const palettes = {
  test: {
    ...vars,
  },
  "c55a73a5-9c72-4c7b-92a1-ca0e67d2d2f2": {
    ...vars,

    primarySpacing: 16,

    primary: "#0B92B7",
    primaryBorderColor: "#1D2529",
    primaryBackground: "#121517",
    primaryBorderRadius: 4,

    secondary: "#2f3133",

    inputBackground: "#171C1F",
    inputBorderRadius: 4,
    inputBorderColor: "#212A2F",

    defaultBackground: "#000000",

    global: {
      ".MuiIntro": {
        "&:before": {
          content: '""',
          position: "fixed",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          // background: `url(/images/${THEME_BRAND}/background.png) no-repeat center center fixed`,
          backgroundSize: "cover",
          display: "inline-block",
          opacity: 1,
          zIndex: -1,
        },
      },
      ".MuiLogo": {
        width: "200px",
        height: "100px",
        // background: `url(/images/${THEME_BRAND}/logo.png) no-repeat center center`,
        backgroundSize: "contain",
      },
    },
  },
  "4f2550f0-d262-4f01-b5df-f84f3aa4d755": {
    ...vars,

    primarySpacing: 16,

    primary: "#ff6c00",
    primaryBorderColor: "#3a4d57",
    primaryBackground: "#212a2f",
    primaryBorderRadius: 3,

    secondary: "#303f47",

    inputBackground: "#171c1f",
    inputBorderRadius: 4,
    inputBorderColor: "#3a4d57",

    defaultBackground: "#000000",

    global: {
      ".MuiIntro": {
        "&:before": {
          content: '""',
          position: "fixed",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          // background: `url(/images/${THEME_BRAND}/background.jpg) no-repeat center center fixed`,
          backgroundSize: "cover",
          display: "inline-block",
          opacity: 0.1,
          zIndex: -1,
        },
      },
      ".MuiLogo": {
        width: "200px",
        height: "100px",
        // background: `url(/images/${THEME_BRAND}/logo.png) no-repeat center center`,
        backgroundSize: "contain",
      },
    },
  },
};

export const templateTheme = (vars) => ({
  spacing: (factor) => factor * 0.5 * vars.primarySpacing,
  palette: {
    type: "dark",
    primary: {
      main: vars.primary,
    },
    secondary: {
      main: vars.secondary,
    },
    background: {
      main: vars.primaryBackground,
      default: vars.defaultBackground,
      input: vars.inputBackground,
      primaryBorder: vars.primaryBorderColor,
      inputBorder: vars.inputBorderColor,
    },
  },
  typography: {
    h5: {
      fontSize: "10px",
      textTransform: "uppercase",
      margin: "unset",
      fontWeight: 500,
      letterSpacing: "1.5px",
    },
    h4: {
      fontSize: "1.15rem",
      margin: "unset",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.5rem",
      margin: "unset",
      fontWeight: "normal",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          "scrollbar-width": "5px",
        },
        "*::-webkit-scrollbar": {
          width: "5px",
        },
        "*::-webkit-scrollbar-track": {
          // boxShadow: `inset 0 0 6px ${vars.primaryBorderColor}`,
          // webkitBoxShadow: `inset 0 0 6px ${vars.primaryBorderColor}`,
          backgroundColor: "transparent",
          // outline: `1px solid ${vars.primaryBackground}`,
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: vars.primary,
          // outline: `1px solid ${vars.primaryBorderColor}`,
        },
        "input:-webkit-autofill": {
          "-webkit-box-shadow": `0 0 0 30px ${vars.inputBackground} inset !important`,
        },
        // 'input:-webkit-autofill:hover': {
        //   '-webkit-box-shadow': '0 0 0 30px black inset !important',
        // },
        // 'input:-webkit-autofill:focus': {
        //   '-webkit-box-shadow': '0 0 0 30px black inset !important',
        // },
        // 'input:-webkit-autofill:active': {
        //   '-webkit-box-shadow': '0 0 0 30px black inset !important',
        // },
        "html, body, #root": {
          height: "100vh",
        },
        ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":
          {
            backgroundColor: "unset !important",
          },
        ".MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label": {
          backgroundColor: "unset",
        },
        ".MuiBox-root": {
          borderColor: `${vars.primaryBorderColor} !important`,
        },
        ".MuiWidget-root": {
          height: "100%",
          // height: '550px',
          // maxHeight: '550px',
        },
        ".MuiMenuLink": {
          color: "unset",
          textDecoration: "none",
        },
        ".MuiNavLink": {
          color: vars.primary,
          textDecoration: "none",
          margin: `0 ${vars.primarySpacing / 4}px`,
        },
        ".MuiFullSize": {
          width: "100%",
          height: "100%",
          display: "flex",
        },
        ".MuiCardsContainer": {
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
          gridAutoRows: "minmax(180px, auto)",
          gridGap: vars.primarySpacing,
        },
        ".markdown-body a": {
          color: vars.primary,
        },
        ".markdown-body h1:first-child": {
          marginTop: "0",
        },
        ".react-resizable-handle": {
          borderColor: `transparent transparent ${vars.primaryBorderColor} transparent !important`,
        },
        ".react-grid-item": {
          overflow: "hidden",
          border: `1px solid ${vars.primaryBorderColor}`,
          borderRadius: vars.primaryBorderRadius,
          backgroundColor: vars.primaryBackground,
        },
        ".MuiLayout-Backdrop": {
          zIndex: "999999 !important",
          backgroundColor: "#000000dd !important",
        },
        ...vars.global,
      },
    },
    MuiTablePagination: {
      toolbar: {
        paddingRight: vars.primarySpacing,
        padding: vars.primarySpacing,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
        borderTop: `1px solid ${vars.primaryBorderColor}`,
        padding: vars.primarySpacing,
      },
      stickyHeader: {
        backgroundColor: vars.secondary,
      },
    },
    MuiList: {
      root: {
        "& .active": {
          display: "block",
          backgroundColor: vars.primary,
          borderRight: "4px solid",
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "unset",
        marginRight: vars.primarySpacing,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: vars.primaryBackground,
      },
      rounded: {
        borderRadius: vars.primaryBorderRadius,
      },
      outlined: {
        border: `1px solid ${vars.primaryBorderColor}`,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: vars.inputBorderRadius,
        "&.Mui-disabled": {
          borderColor: vars.secondary,
        },
      },
      adornedEnd: {
        marginRight: "8px",
      },
      notchedOutline: {
        borderColor: vars.inputBorderColor,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: vars.inputBackground,
      },
    },
    MuiDialog: {
      paper: {
        minWidth: "350px",
      },
    },
    MuiAvatar: {
      root: {
        "&.MuiAvatar-profile": {
          height: "120px",
          width: "120px",
        },
      },
    },
    MuiCardMedia: {
      media: {
        width: "100%",
        height: "100%",
      },
    },
    MuiIconButton: {
      root: {
        padding: "unset",
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: vars.secondary,
      },
    },
    MuiTreeItem: {
      root: {
        "&:focus > .MuiTreeItem-content .MuiTreeItem-label": {
          backgroundColor: "unset",
        },
      },
      iconContainer: {
        "& svg": {
          fontSize: "1.5rem",
        },
      },
    },
    MuiToggleButtonGroup: {
      groupedHorizontal: {
        "&:not(:first-child)": {
          borderLeft: `1px solid ${vars.primaryBorderColor}`,
        },
      },
    },
    MuiToggleButton: {
      root: {
        border: `1px solid ${vars.primaryBorderColor}`,
        backgroundColor: vars.inputBackground,
        "&.Mui-selected": {
          backgroundColor: vars.primary,
        },
      },
    },
    MuiButton: {
      contained: {
        "&.Mui-disabled": {
          backgroundColor: vars.secondary,
        },
      },
      containedPrimary: {
        color: "white",
      },
    },
    MuiMenuItem: {
      root: {
        textTransform: "capitalize",
      },
    },
    MuiSelect: {
      selectMenu: {
        textTransform: "capitalize",
      },
    },
    MuiGrid: {
      item: {
        "&.MuiGrid-Grow": {
          flex: "1 1",
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: vars.secondary,
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: vars.inputBackground,
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        backgroundColor: vars.secondary,
        borderRadius: vars.primaryBorderRadius,
      },
    },
  },
});
