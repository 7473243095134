// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Box,
  Dialog,
  FormControl,
} from "@material-ui/core/";
import { useSnackbar } from "notistack";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import api from "../../api";
import { passwordValid } from "../../utils/validations";

export default function ChangePassword() {
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [login, setLogin] = useState({
    e_mail: "",
  });
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (location?.state?.email) {
      setLogin((prev) => ({ ...prev, e_mail: location.state.email }));
    } else {
      history.push("/reset-password");
    }
  }, [history, location]);

  const { e_mail } = login;

  const checkInputCode = (valueInput) =>
    valueInput.match(/^\d+$/g) && valueInput.length === 6;

  const ChangePassword = () => {
    api
      .post("/public/auth/confirm-forgot-password/", {
        login: e_mail,
        code: code,
        new_password: password,
      })
      .then((response) => {
        if (response.status === 200) {
          history.push("/log-in");
        }
      })
      .then(() =>
        enqueueSnackbar("Password changed successfully", { variant: "success" })
      );
  };

  const SendCode = () => {
    api.post("/public/auth/forgot-password/", { login: e_mail }).then(() =>
      enqueueSnackbar("A confirmation code has been sent to your email", {
        variant: "success",
      })
    );
  };

  return (
    <Dialog maxWidth="xs" open>
      <Paper variant="outlined">
        <Box
          p={2}
          pb={0}
          width={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        ></Box>
        <Box
          p={2}
          width={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container spacing={2} direction="column">
            <Grid item xs>
              <FormControl size="small" variant="outlined" fullWidth>
                <TextField
                  size="small"
                  name="E-Mail"
                  label="E-Mail"
                  variant="outlined"
                  value={e_mail}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl size="small" variant="outlined" fullWidth>
                <TextField
                  size="small"
                  name="code"
                  label="Confirmation code"
                  variant="outlined"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  error={!checkInputCode(code) && code !== ""}
                  helperText={
                    !checkInputCode(code) && code !== "" && "Incorrect code"
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl size="small" variant="outlined" fullWidth>
                <TextField
                  name="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  size="small"
                  variant="outlined"
                  error={!passwordValid(password) && password !== ""}
                  helperText={
                    !passwordValid(password) &&
                    password !== "" &&
                    "Password should be between 8-99 symbols, contain numbers, uppercase, lowercase and special characters"
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl size="small" variant="outlined" fullWidth>
                <Button
                  style={{ marginTop: "1rem" }}
                  variant="contained"
                  color="primary"
                  disabled={!checkInputCode(code) || !passwordValid(password)}
                  onClick={ChangePassword}
                >
                  Change password
                </Button>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl size="small" variant="outlined" fullWidth>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={SendCode}
                >
                  Send code one more time
                </Button>
              </FormControl>
            </Grid>
            <Box p={2}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs>
                  <NavLink className="MuiNavLink" to="/log-in">
                    Go to login page
                  </NavLink>
                </Grid>
                <Box textAlign="center" marginTop="1rem">
                  We have sent you a recovery code to your email address. Enter
                  it in the field above and set a new password.
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
}
