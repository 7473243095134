// @ts-nocheck

import axios from "axios";
import { EventEmitter } from "eventemitter3";

const API_URL = process.env.REACT_APP_LICENCE_SERVER_URL;

const instance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

export const errorEmitter = new EventEmitter();

export const noIntercept = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

export const apiNoAuth = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

export const apiForFile = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: { "content-type": "multipart/form-data" },
});

instance.interceptors.request.use(
  (config) => {
    config.headers["access-token"] = localStorage.getItem("access-token");
    return config;
  },
  (error) => Promise.reject(error)
);

apiForFile.interceptors.request.use(
  (config) => {
    config.headers["access-token"] = localStorage.getItem("access-token");
    return config;
  },
  (error) => Promise.reject(error)
);

const reqInterceptor = (error) => {
  (error as any).handledStatus = false;
  const defaultError = new Error(
    "Something went wrong. Try again or contact support"
  );

  if (!error.response) {
    errorEmitter.emit("unexpected-error", defaultError);
    return Promise.reject(defaultError);
  }

  switch (error.response.status) {
    case 401:
      errorEmitter.emit("unauthorized", error);
      break;
    case 403:
    case 404:
    case 400:
    case 422:
    case 423:
      errorEmitter.emit("unexpected-error", error.response.data);
      break;
    case 413:
      errorEmitter.emit("unexpected-error", error.response.data);
      break;
    case 500:
      errorEmitter.emit(
        "internal-server-error",
        error.response.data?.message
          ? error.response.data.message
          : error.response.data,
        error
      );
      break;
  }
  return Promise.reject(error);
};

instance.interceptors.response.use((response) => response, reqInterceptor);

apiNoAuth.interceptors.response.use((response) => response, reqInterceptor);

apiForFile.interceptors.response.use((response) => response, reqInterceptor);

export default instance;
