// @ts-nocheck
import React from "react";
import { Typography, Box, Button } from "@material-ui/core";
import { useAppStore } from "../AppStore";
import { firstLetterUpperCase } from "../utils/validations";

export default function Header({ title }) {
  const [VALUE] = useAppStore();

  const onLogOutClick = () => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");
    document.location = "/";
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box pl={2} pt={1}>
        <Typography variant="h3" color="inherit">
          {title}
        </Typography>
      </Box>
      <Box pr={2} pt={1} display="flex" alignItems="center">
        {VALUE.currentUser !== undefined && (
          <Box pr={10} pt={1}>
            <Box color="#EAEAEA" fontSize={16} fontWeight="bold">
              {VALUE.currentUser.first_name !== undefined &&
                VALUE.currentUser.last_name !== undefined &&
                `${firstLetterUpperCase(
                  VALUE.currentUser.first_name
                )} ${firstLetterUpperCase(VALUE.currentUser.last_name)}`}
            </Box>
            <Box color="rgba(234, 234, 234, 0.7)" fontSize={14}>
              {VALUE.currentUser.company !== undefined &&
                VALUE.currentUser.role !== undefined &&
                `${firstLetterUpperCase(VALUE.currentUser.company)} ${
                  VALUE.currentUser.role === "admin"
                    ? "Administrator"
                    : "Integrator"
                }`}
            </Box>
          </Box>
        )}
        <Button variant="contained" color="primary" onClick={onLogOutClick}>
          Log Out
        </Button>
      </Box>
    </Box>
  );
}
