// @ts-nocheck
import React, { useEffect, useState } from "react";
import api from "../../api";
import { usePagination } from "../../hooks";
import { Box, Typography, Grid, Paper, Button } from "@material-ui/core";
import Layout from "../Layout";
import { generateGridData } from "./GenerateGridData";
import { DataGrid } from "../../partials/table/DataGrid";
import { useAppStore } from "../../AppStore";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import AddResellerModal from "../../partials/modal/AddResellerModal";

export default function IntegratorsList() {
  const [VALUE] = useAppStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [openAddIntegratorModal, setOpenAddIntegratorModal] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [gridData, setGridData] = useState({ gridColumns: [], gridRows: [] });
  const {
    pagination,
    paginationSizeHandler,
    paginationPageHandler,
    paginationAmountHandler,
  } = usePagination();

  useEffect(() => {
    if (
      VALUE.currentUser.role !== undefined &&
      VALUE.currentUser.role !== "admin"
    ) {
      history.push("/");
    }
  }, [VALUE.currentUser.role, history]);

  const handlerIntegratorModal = () => {
    setOpenAddIntegratorModal((prev) => !prev);
  };

  const getIntegratorsList = (page, rows) => {
    setShowLoader(true);
    api
      .post("/public/v1/users/", {
        pagination: {
          pgoffset: page,
          pgsize: rows,
        },
      })
      .then(({ data }) => {
        if (!data) return;
        const { items, amount } = data;
        const dataForGrid = generateGridData(items);
        setGridData(dataForGrid);
        paginationAmountHandler(amount);
        setShowLoader(false);
      });
  };

  const handlerEnableChange = (user_id, active) => {
    setShowLoader(true);
    api
      .patch(`/public/users/${user_id}`, {
        active: !active,
      })
      .then(() => {
        getIntegratorsList(pagination.offset, pagination.size);
        enqueueSnackbar("Success", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  useEffect(() => {
    setShowLoader(true);
    getIntegratorsList(pagination.offset, pagination.size);
    // eslint-disable-next-line
  }, [pagination.offset, pagination.size]);

  return (
    <Layout title="Integrators">
      <Box p={2} width={1}>
        <Grid item xs={12}>
          <Paper>
            <Box p={3}>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography align="left" variant="h3">
                  Integrators List
                </Typography>
                <Button onClick={handlerIntegratorModal} color="primary">
                  Add New Integrator
                </Button>
              </Box>
              <DataGrid
                isLoading={showLoader}
                pagination={pagination}
                onSizeChange={paginationSizeHandler}
                onPageChange={paginationPageHandler}
                {...gridData}
                handlerEnableChange={handlerEnableChange}
                data={gridData.gridRows.length > 0}
              />
            </Box>
          </Paper>
        </Grid>
        <AddResellerModal
          show={openAddIntegratorModal}
          handleClose={handlerIntegratorModal}
          getIntegratorsList={() =>
            getIntegratorsList(pagination.offset, pagination.size)
          }
        />
      </Box>
    </Layout>
  );
}
