// @ts-nocheck
import React, { useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Box,
  Dialog,
  FormControl,
} from "@material-ui/core/";
import { useSnackbar } from "notistack";
import { useHistory, NavLink } from "react-router-dom";
import api from "../../api";
import { checkValidEmail } from "../../utils/validations";

export default function ResetPassword() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [login, setLogin] = useState("");

  const SendCode = () => {
    api
      .post("/public/auth/forgot-password/", {
        login: login,
      })
      .then((response) => {
        if (response.status === 200) {
          history.push("/change-password/", { email: login });
        }
      })
      .then(() =>
        enqueueSnackbar("A confirmation code has been sent to your email", {
          variant: "success",
        })
      );
  };

  return (
    <Dialog maxWidth="xs" open>
      <Paper variant="outlined">
        <Box
          p={2}
          pb={0}
          width={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        ></Box>
        <Box
          p={2}
          width={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container spacing={2} direction="column">
            <Grid item xs>
              <FormControl size="small" variant="outlined" fullWidth>
                <TextField
                  size="small"
                  name="login"
                  label="E-Mail"
                  variant="outlined"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                  error={!checkValidEmail(login) && login !== ""}
                  helperText={
                    !checkValidEmail(login) &&
                    login !== "" &&
                    "Wrong login format"
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl size="small" variant="outlined" fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!checkValidEmail(login)}
                  onClick={SendCode}
                >
                  Send a code
                </Button>
              </FormControl>
            </Grid>
            <Box p={2}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs>
                  <NavLink className="MuiNavLink" to="/log-in">
                    Back to login
                  </NavLink>
                </Grid>
                <Box textAlign="center" marginTop="1rem">
                  If you need to reset your password or to restore the account,
                  please enter your email address and move to the next step.
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
}
