// @ts-nocheck
import React, { useState } from "react";
import { TextField, Grid, Button } from "@material-ui/core";
import BasicDialogue from "./BasicDialogue";
import { onlyNumbersValidation } from "../../utils/validations";

export default function AddChannels({
  show,
  handleClose,
  item,
  addChannelsActivatedExpired,
}) {
  const [camerasCount, setCamerasCount] = useState(
    String(item.cameras_count) || ""
  );

  const handleClearForm = () => {
    setCamerasCount(String(item.cameras_count));
    handleClose();
  };

  const isOldState = camerasCount === String(item.cameras_count);
  const channelsLength = Number(camerasCount) > 100;

  const Content = (
    <Grid container spacing={2} direction="column">
      <Grid item xs>
        <TextField
          name="cameras_count"
          size="small"
          fullWidth
          label="Channels"
          variant="outlined"
          value={camerasCount}
          onChange={(e) => setCamerasCount(e.target.value)}
          error={
            !onlyNumbersValidation(camerasCount) ||
            camerasCount === "" ||
            channelsLength
          }
          helperText={
            (!onlyNumbersValidation(camerasCount) ||
              camerasCount === "" ||
              channelsLength) &&
            "The Channels count field accepts only numbers from 1 to 100"
          }
          placeholder="Please indicate the number of channels"
        />
      </Grid>
    </Grid>
  );

  const Action = (
    <>
      <Button variant="outlined" color="primary" onClick={handleClearForm}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => addChannelsActivatedExpired(item.id, camerasCount)}
        disabled={
          !onlyNumbersValidation(camerasCount) || isOldState || channelsLength
        }
      >
        Submit
      </Button>
    </>
  );

  return (
    <>
      <BasicDialogue
        show={show}
        handleClose={handleClose}
        title="Add Channels"
        Content={Content}
        Action={Action}
      />
    </>
  );
}
