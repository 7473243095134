// @ts-nocheck

import React, { useEffect, useState } from "react";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { createTheme, StylesProvider } from "@material-ui/core/styles";
import { templateTheme, palettes } from "../Theme.ts";
import { THEME_BRAND } from "../utils/constans";

// import "../github-markdown-dark.css";

export const ThemeProvider = (props) => {
  const [palette] = useState(palettes[THEME_BRAND]);
  const [theme, setTheme] = useState(
    createTheme(templateTheme(palettes[THEME_BRAND]))
  );

  useEffect(() => {
    setTheme(createTheme(templateTheme(palette)));
  }, [palette]);

  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <CssBaseline />
      </StylesProvider>
      {props.children}
    </MuiThemeProvider>
  );
};
