// @ts-nocheck

import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
// import Licenses from "../pages/Licenses";
import LicensesList from "../pages/licenses/LicensesList";
// import Resellers from "../pages/Resellers";
import IntegratorsList from "../pages/integrators/IntegratorsList";
import Login from "../pages/auth/Login";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { useSnackbar } from "notistack";
import { errorEmitter } from "../api";
import ResetPassword from "../pages/auth/ResetPassword";
import ChangePassword from "../pages/auth/ChangePassword";

export const checkAuth = () => {
  const jwt = localStorage.getItem("access-token");

  if (jwt) {
    try {
      const authData: JwtPayload = jwtDecode(jwt);
      const expiration = new Date(Number(authData.exp) * 1000);

      if (new Date() > expiration) {
        localStorage.removeItem("access-token");
        return false;
      }
    } catch (e) {
      localStorage.removeItem("access-token");
      return false;
    }
    return true;
  }
  return false;
};

const modifyRedirect = (redirectPath, pathname) => {
  // if (
  //   startsWith(pathname, "/shared-widget") ||
  //   startsWith(pathname, "/shared-layout")
  // ) {
  //   return redirectPath + pathname;
  // }
  return redirectPath;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { pathname } = useLocation();

  const redirectPath = modifyRedirect("/log-in", pathname);

  return (
    <Route
      {...rest}
      render={(props) =>
        checkAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: redirectPath, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const Navigation = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const errorHandler = (
    error: HandledError,
    message: string,
    redirectUrl?: string
  ) => {
    if (!error.handledStatus) {
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 5000,
        preventDuplicate: true,
      });
      error.handledStatus = true;

      if (redirectUrl) {
        history.push(modifyRedirect(redirectUrl, pathname), { from: pathname });
      }
    }
  };

  const serverErrorHandler = (message: string, error: HandledError) => {
    errorHandler(
      error,
      `Internal server error${message ? `: ${message}` : ""}`
    );
  };

  const unauthorizedHandler = (error: HandledError) => {
    errorHandler(error, "Not Authorized", "/log-in");
  };

  const serverUnexpectedHandler = (error: HandledError) => {
    errorHandler(error, error.message);
  };

  const handleForbiddenWithRedirect = (error: HandledError) => {
    errorHandler(error, "Forbidden", "/log-in");
  };

  const handleForbidden = (error: HandledError) => {
    errorHandler(error, "Forbidden");
  };

  useEffect(() => {
    errorEmitter.on("unauthorized", unauthorizedHandler);
    errorEmitter.on("internal-server-error", serverErrorHandler);
    errorEmitter.on("unexpected-error", serverUnexpectedHandler);

    return () => {
      errorEmitter.removeListener("unauthorized", unauthorizedHandler);
      errorEmitter.removeListener("internal-server-error", serverErrorHandler);
      errorEmitter.removeListener("unexpected-error", serverUnexpectedHandler);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pathname === "/profile") {
      errorEmitter.removeListener("forbidden", handleForbiddenWithRedirect);
      errorEmitter.on("forbidden", handleForbidden);
    } else {
      errorEmitter.removeListener("forbidden", handleForbidden);
      errorEmitter.on("forbidden", handleForbiddenWithRedirect);
    }

    return () => {
      errorEmitter.removeListener("forbidden", handleForbidden);
      errorEmitter.removeListener("forbidden", handleForbiddenWithRedirect);
    };
    // eslint-disable-next-line
  }, [pathname]);
  return (
    <Switch>
      {/* <PrivateRoute path="/" exact component={Licenses} /> */}
      <PrivateRoute path="/" exact component={LicensesList} />
      {/* <PrivateRoute path="/integrators" exact component={Resellers} /> */}
      <PrivateRoute path="/integrators" exact component={IntegratorsList} />
      <Route path="/log-in" exact component={Login} />
      <Route path="/reset-password" exact component={ResetPassword} />
      <Route path="/change-password/" component={ChangePassword} />
    </Switch>
  );
};
export default Navigation;
