// @ts-nocheck

import React from "react";
import { Box } from "@material-ui/core";
import Header from "../partials/Header";
import LeftMenu from "../partials/LeftMenu";

const Layout = ({ children, title }) => {
  return (
    <Box display="flex" flexWrap="nowrap" width={1} height={1}>
      <LeftMenu />
      <Box
        width={1}
        height={1}
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <Header title={title} />
        <Box
          width={1}
          height={1}
          display="flex"
          flexDirection="column"
          overflow="auto"
          paddingTop={1}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
