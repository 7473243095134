// @ts-nocheck

import { useState, useCallback } from "react";

export const usePagination = (defaultData = {}) => {
  const [pagination, setPagination] = useState({
    offset: 0,
    size: 10,
    amount: null,
    ...defaultData,
  });
  const paginationSizeHandler = (page, size) => {
    setPagination((prevState) => ({ ...prevState, offset: page, size: size }));
  };

  const paginationPageHandler = useCallback(
    (page) => {
      setPagination((prevState) => ({
        ...prevState,
        offset: page * pagination.size,
      }));
    },
    [pagination.size]
  );

  const paginationAmountHandler = useCallback((amount) => {
    setPagination((prevState) => ({ ...prevState, amount }));
  }, []);

  return {
    pagination,
    paginationSizeHandler,
    paginationPageHandler,
    paginationAmountHandler,
    setPagination,
  };
};
