// @ts-nocheck

export const BRANDS = {
  DW: "4f2550f0-d262-4f01-b5df-f84f3aa4d755",
  NX: "c55a73a5-9c72-4c7b-92a1-ca0e67d2d2f2",
};

// export const TOKEN_REFRESH_INTERVAL_TIMER = Number.parseInt(
//   process.env.REACT_APP_TOKEN_REFRESH_INTERVAL_TIMER || "1000",
//   10
// );
export const THEME_BRAND = process.env.REACT_APP_THEME_BRAND || BRANDS.NX;
