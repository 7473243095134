// @ts-nocheck
import React, { createContext, useContext, useState, useEffect } from "react";
import api from "./api";

const AppStoreContext = createContext();

export const AppStoreProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserLogin, setIsUserLogin] = useState(false);

  const jwt = localStorage.getItem("access-token");

  useEffect(() => {
    if (jwt) {
      api.get("/public/me/").then((response) => setCurrentUser(response.data));
    }
  }, [jwt, isUserLogin]);

  return (
    <AppStoreContext.Provider
      value={[
        {
          currentUser,
          isUserLogin,
          isMenuOpen,
        },
        {
          setCurrentUser,
          setIsUserLogin,
          setIsMenuOpen,
        },
      ]}
    >
      {children}
    </AppStoreContext.Provider>
  );
};

export const useAppStore = () => useContext(AppStoreContext);
