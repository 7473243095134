// @ts-nocheck
import React from "react";
import { TableCell, Typography, IconButton, Box } from "@material-ui/core";
import { timeConverter } from "../../utils/validations";
import CustumMenus from "../../partials/CustumMenus";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import EditContractId from "../../partials/modal/EditContractId";
import { firstLetterUpperCase } from "../../utils/validations";
import EditDays from "../../partials/modal/EditDays";
import EditChannels from "../../partials/modal/EditChannels";

export const generateGridData = (result) => {
  const gridColumns = [
    {
      icon: (name, handlerSort) => {
        const defaultValue = "company_name";
        const descValue = "company_name desc";
        return (
          <Box display="flex">
            <Typography>{name}</Typography>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              color="primary"
              onClick={() => handlerSort(defaultValue, descValue)}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Box>
        );
      },
      name: "Company",
      alignPosition: "center",
      CellRenderer: (value) => {
        const companyName = value.company_name;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{companyName}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Company Contact",
      alignPosition: "center",
      CellRenderer: (value) => {
        const first_name = value.company_owner_first_name;
        const last_name = value.company_owner_last_name;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{first_name}</Typography>
            <Typography>{last_name}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Key",
      alignPosition: "center",
      CellRenderer: (value) => {
        const key = value.id;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{key}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "License ID",
      alignPosition: "center",
      CellRenderer: (value) => {
        const licenseID = value.license_id;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{licenseID}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Contract",
      alignPosition: "center",
      CellRenderer: (value, handler) => {
        const contractId = value.contract_id;
        const isTrial = !value.trial;

        return (
          <TableCell align="center">
            <Box display="flex" justifyContent="space-evenly">
              <Typography
                style={{
                  color: value.status === "expired" && "rgba(255,0,0, 0.7)",
                }}
              >
                {contractId}
              </Typography>
              {isTrial && (
                <EditContractId
                  id={value.id}
                  handler={handler}
                  valueId={value.contract_id}
                />
              )}
            </Box>
          </TableCell>
        );
      },
    },
    {
      name: "Channels",
      alignPosition: "center",
      CellRenderer: (value, handler) => {
        const cameras = value.cameras_count;
        const notActivated = value.status === "not activated";
        const key = value.id;

        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Box
              style={
                notActivated
                  ? {
                      display: "flex",
                      justifyContent: "space-evenly",
                      width: "100px",
                    }
                  : { display: "flex", justifyContent: "center" }
              }
            >
              <Typography>{cameras}</Typography>
              {notActivated && (
                <EditChannels
                  cameras={String(cameras)}
                  id={key}
                  handler={handler}
                />
              )}
            </Box>
          </TableCell>
        );
      },
    },
    {
      name: "Days",
      alignPosition: "center",
      CellRenderer: (value, handler) => {
        const days = value.days;
        const notActivated = value.status === "not activated";
        const key = value.id;

        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Box
              style={
                notActivated
                  ? {
                      display: "flex",
                      justifyContent: "space-evenly",
                      width: "100px",
                    }
                  : { display: "flex", justifyContent: "center" }
              }
            >
              <Typography>{days}</Typography>
              {notActivated && (
                <EditDays item={days} handler={handler} id={key} />
              )}
            </Box>
          </TableCell>
        );
      },
    },
    {
      icon: (name, handlerSort) => {
        const defaultValue = "activated_at";
        const descValue = "activated_at desc";
        return (
          <Box display="flex">
            <Typography>{name}</Typography>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              color="primary"
              onClick={() => handlerSort(defaultValue, descValue)}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Box>
        );
      },
      name: "Activation Date",
      alignPosition: "center",
      CellRenderer: (value) => {
        const activatedAt = value.activated_at;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>
              {activatedAt !== null ? timeConverter(activatedAt) : ""}
            </Typography>
          </TableCell>
        );
      },
    },
    {
      icon: (name, handlerSort) => {
        const defaultValue = "expires_at";
        const descValue = "expires_at desc";
        return (
          <Box display="flex">
            <Typography>{name}</Typography>
            <IconButton
              color="primary"
              style={{ backgroundColor: "transparent" }}
              onClick={() => handlerSort(defaultValue, descValue)}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Box>
        );
      },
      name: "Expiration Date",
      alignPosition: "center",
      CellRenderer: (value) => {
        const expiresAt = value.expires_at;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>
              {expiresAt !== null ? timeConverter(expiresAt) : ""}
            </Typography>
          </TableCell>
        );
      },
    },
    {
      icon: (name, handlerSort) => {
        const defaultValue = "days_left";
        const descValue = "days_left desc";
        return (
          <Box display="flex">
            <Typography>{name}</Typography>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              color="primary"
              onClick={() => handlerSort(defaultValue, descValue)}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Box>
        );
      },
      name: "Days Left",
      alignPosition: "center",
      CellRenderer: (value) => {
        const daysLeft = value.days_left;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{daysLeft}</Typography>
          </TableCell>
        );
      },
    },
    // {
    //   name: "Account Manager",
    //   alignPosition: "center",
    //   CellRenderer: (value) => {
    //     const email = value.company_owner_email;
    //     return (
    //       <TableCell
    //         align="center"
    //         style={{
    //           color: value.status === "expired" && "rgba(255,0,0, 0.7)",
    //         }}
    //       >
    //         <Typography>{email}</Typography>
    //       </TableCell>
    //     );
    //   },
    // },
    {
      name: "Type",
      alignPosition: "center",
      CellRenderer: (value) => {
        const type = value.trial;
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{type ? "Demo" : "Regular"}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Status",
      alignPosition: "center",
      CellRenderer: (value) => {
        const status = firstLetterUpperCase(
          value.status === "disabled" ? "suspended" : value.status
        );
        return (
          <TableCell
            align="center"
            style={{
              color: value.status === "expired" && "rgba(255,0,0, 0.7)",
            }}
          >
            <Typography>{status}</Typography>
          </TableCell>
        );
      },
    },

    {
      name: "Action",
      alignPosition: "center",
      CellRenderer: (
        _,
        rowData,
        handlerEnableChange,
        editDays,
        editChannels,
        extendActivatedExpired,
        addChannelsActivatedExpired
      ) => {
        const isTrialLicense = rowData.trial;
        return (
          <TableCell align="center">
            {!isTrialLicense && (
              <CustumMenus
                element={rowData}
                handlerEnableChange={handlerEnableChange}
                editDays={editDays}
                editChannels={editChannels}
                extendActivatedExpired={extendActivatedExpired}
                addChannelsActivatedExpired={addChannelsActivatedExpired}
                hasHistory
              />
            )}
          </TableCell>
        );
      },
    },
  ];

  const gridRows = result;

  return {
    gridColumns,
    gridRows,
  };
};
