// @ts-nocheck

import React, { useState } from "react";
import {
  Paper,
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  Dialog,
  Typography,
} from "@material-ui/core/";
import { NavLink, useHistory } from "react-router-dom";
import api from "../../api";
import { checkValidEmail, passwordValid } from "../../utils/validations";
import { useAppStore } from "../../AppStore";

const LogIn = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [VALUE, HANDLER] = useAppStore();

  const history = useHistory();

  const signIn = () => {
    api
      .post("/public/auth/", {
        login: login,
        password: password,
      })
      .then(({ data }) => {
        localStorage.setItem("access-token", data.access_token);
        localStorage.setItem("refresh-token", data.refresh_token);
      })
      .then(() => {
        HANDLER.setIsUserLogin(!VALUE.isUserLogin);
        history.push("/");
      });
  };

  return (
    <Dialog maxWidth="xs" open>
      <Paper variant="outlined" style={{ maxWidth: 350 }}>
        <Box
          p={2}
          pb={0}
          width={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        ></Box>
        <Box
          p={2}
          width={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container spacing={2} direction="column">
            <Grid item xs>
              <FormControl size="small" variant="outlined" fullWidth>
                <TextField
                  size="small"
                  name="login"
                  label="E-Mail"
                  variant="outlined"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                  error={!checkValidEmail(login) && login !== ""}
                  helperText={
                    !checkValidEmail(login) &&
                    login !== "" &&
                    "Invalid format email"
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl size="small" variant="outlined" fullWidth>
                <TextField
                  name="password"
                  label="Password"
                  value={password}
                  type="password"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setPassword(e.target.value)}
                  error={!passwordValid(password) && password !== ""}
                  helperText={
                    !passwordValid(password) &&
                    password !== "" &&
                    "Password should be between 8-99 symbols, contain numbers, uppercase, lowercase and special characters"
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl size="small" variant="outlined" fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!checkValidEmail(login) || !passwordValid(password)}
                  onClick={signIn}
                >
                  Sign In
                </Button>
              </FormControl>
            </Grid>
            <Box p={2}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs>
                  <Typography variant="subtitle2">
                    Forgot password?{" "}
                    <NavLink className="MuiNavLink" to="/reset-password">
                      Reset Password
                    </NavLink>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default LogIn;
